import mapVerify from '@/utils/formValidate'
import myTransform from '@/libs_sz/utils/transform'
import { t } from '@/libs_sz/locale'
const getFormatter = (data, value) => {
  const getValue = myTransform.arrToObject(data, 'value', 'label')[value] || value
  return getValue === 'null' || getValue === 0 ? '' : getValue
}
const getValue = value => (value ? String(value) : String(value) === '0' ? '0' : '')
export const adjustReason = [
  {
    value: 1,
    label: t('lang.gles.orderManage.adjustReason.personAdjust')
  }
]
export const stockStatusList = [
  {
    value: '0',
    label: t('lang.gles.base.containerStatusNormal')
  },
  {
    value: '10',
    label: t('lang.gles.base.containerStatusOccupy')
  },
  {
    value: '20',
    label: t('lang.gles.base.freeze')
  }
]
export const adjustTargetList = [
  {
    value: 1,
    label: t('lang.gles.base.factoryPosition')
  },
  {
    value: 2,
    label: t('lang.gles.strategy.fixGoodsPosition')
  },
  {
    value: 3,
    label: t('lang.gles.orderManage.movableGoodsPositionId')
  }
]
// 获取 更多查询字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 库存调整单号
    {
      name: 'orderNumber',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.orderNumber')
    },
    // 工厂编码
    {
      name: 'factoryId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.factoryId'),
      filterable: true,
      data: that.factoryList,
      mchange: that.handleChange
    },
    // 仓库编码
    {
      name: 'warehouseId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.warehouseId'),
      filterable: true,
      disabled: !!formModel.workshopId,
      data: that.queryWarehouseList || [],
      mchange: that.handleChange
    },
    // 车间编码
    {
      name: 'workshopId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.workshopId'),
      filterable: true,
      disabled: !!formModel.warehouseId,
      data: that.queryWorkshopList || [],
      mchange: that.handleChange
    },
    // todo: 本期暂不涉及区域调整 暂时隐藏
    // // 区域编码
    // {
    //   name: 'areaId',
    //   span: 12,
    //   component: 'elSelect',
    //   adaptionw: true,
    //   showWordLimit: false,
    //   label: that.$t('lang.gles.orderManage.areaId'),
    //   filterable: true,
    //   disabled: !!formModel.warehouseId,
    //   data: that.areaList || [],
    //   mchange: that.handleChange
    // },
    // // 产线编码
    // {
    //   name: 'productionLineId',
    //   span: 12,
    //   component: 'elSelect',
    //   adaptionw: true,
    //   showWordLimit: false,
    //   label: that.$t('lang.gles.orderManage.productionLineId'),
    //   filterable: true,
    //   disabled: !!formModel.warehouseId,
    //   data: that.productionLineList || [],
    //   mchange: that.handleChange
    // },
    // 库区编码
    {
      name: 'warehouseAreaId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.warehouseAreaId'),
      filterable: true,
      data: that.queryWarehouseAreaList || [],
      mchange: that.handleChange
    },
    // 库位编码
    {
      name: 'factoryPositionId',
      span: 12,
      component: 'RemoteSelect',
      label: that.$t('lang.gles.orderManage.factoryPositionId'),
      filterable: true,
      labelText: 'factoryPositionCode',
      // data: that.queryFactoryPositionList || [],
      mchange: that.handleChange
    },
    // 货位编码
    {
      name: 'goodsPositionId',
      span: 12,
      component: 'RemoteSelect',
      queryType: 'goodsPositionList',
      label: that.$t('lang.gles.orderManage.goodsPositionId'),
      filterable: true
      // data: that.queryGoodsPositionList || []
    },
    // 创建人
    {
      name: 'createUser',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.common.createUser')
    },
    // 状态
    {
      name: 'status',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.status'),
      filterable: true,
      data: that.stockAdjustOrderStatusList || []
    },
    // 创建时间
    {
      name: 'createTime',
      span: 12,
      component: 'elDatePicker',
      type: 'datetimerange',
      'range-separator': '-',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      label: that.$t('lang.gles.common.createTime')
    },
    // 编辑时间
    {
      name: 'updateTime',
      span: 12,
      component: 'elDatePicker',
      type: 'datetimerange',
      'range-separator': '-',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      label: that.$t('lang.gles.common.updateTime')
    },
    // 完成时间
    {
      name: 'completeTime',
      span: 12,
      component: 'elDatePicker',
      type: 'datetimerange',
      'range-separator': '-',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      label: that.$t('lang.gles.common.completedTime')
    }
  ]
  return moreQueryData
}

// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 库存调整单号
    {
      prop: 'orderNumber',
      isShow: true,
      label: that.$t('lang.gles.orderManage.orderNumber'),
      minWidth: 175,
      showOverflowTooltip: true,
      slotName: 'clickSlot'
    },
    // 调整原因
    {
      prop: 'type',
      isShow: true,
      label: that.$t('lang.gles.orderManage.adjustReason'),
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.adjustReason, row[column])
      }
    },
    // 工厂编码
    {
      prop: 'factoryCodes',
      isShow: true,
      label: that.$t('lang.gles.orderManage.factoryId'),
      width: 100,
      showOverflowTooltip: true
      // formatter(row, column) {
      //   return getFormatter(that.factoryList, row[column])
      // }
    },
    // 仓库编码
    {
      prop: 'warehouseCodes',
      isShow: true,
      label: that.$t('lang.gles.orderManage.warehouseId'),
      width: 100,
      showOverflowTooltip: true
      // formatter(row, column) {
      //   return getFormatter(that.warehouseList, row[column])
      // }
    },
    // 车间编码
    {
      prop: 'workshopCodes',
      isShow: true,
      label: that.$t('lang.gles.orderManage.workshopId'),
      width: 100,
      showOverflowTooltip: true
      // formatter(row, column) {
      //   return getFormatter(that.workshopList, row[column])
      // }
    },
    // // 区域编码
    // {
    //   prop: 'areaId',
    //   isShow: true,
    //   label: that.$t('lang.gles.orderManage.areaId'),
    //   width: 100,
    //   formatter(row, column) {
    //     return getFormatter(that.areaList, row[column])
    //   }
    // },
    // // 产线编码
    // {
    //   prop: 'productionLineId',
    //   isShow: true,
    //   label: that.$t('lang.gles.orderManage.productionLineId'),
    //   width: 100,
    //   formatter(row, column) {
    //     return getFormatter(that.productionLineList, row[column])
    //   }
    // },
    // 库区编码
    {
      prop: 'warehouseAreaCodes',
      isShow: true,
      label: that.$t('lang.gles.orderManage.warehouseAreaId'),
      width: 100,
      showOverflowTooltip: true
      // formatter(row, column) {
      //   return getFormatter(that.warehouseAreaList, row[column])
      // }
    },
    // 库位编码
    {
      prop: 'factoryPositionCodes',
      isShow: true,
      label: that.$t('lang.gles.orderManage.factoryPositionId'),
      width: 100,
      showOverflowTooltip: true
      // formatter(row, column) {
      //   return getFormatter(that.factoryPositionList, row[column])
      // }
    },
    // 货位编码
    {
      prop: 'goodsPositionCodes',
      isShow: true,
      label: that.$t('lang.gles.orderManage.goodsPositionId'),
      width: 110,
      showOverflowTooltip: true
      // formatter(row, column) {
      //   return getFormatter(that.goodsPositionList, row[column])
      // }
    },
    // 状态
    {
      prop: 'status',
      isShow: true,
      label: that.$t('lang.gles.base.status'),
      width: 80,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.stockAdjustOrderStatusList, row[column])
      }
    },
    // 创建人
    {
      prop: 'createUser',
      isShow: true,
      label: that.$t('lang.gles.common.createUser')
    },
    // 创建时间
    {
      prop: 'createTime',
      isShow: true,
      label: that.$t('lang.gles.common.createTime'),
      formatter: { type: 'time' },
      minWidth: 160,
      showOverflowTooltip: true
    },
    // 修改时间
    {
      prop: 'updateTime',
      isShow: true,
      label: that.$t('lang.gles.common.updateTime'),
      formatter: { type: 'time' },
      minWidth: 160,
      showOverflowTooltip: true
    },
    // 完成时间
    {
      prop: 'completeTime',
      isShow: true,
      label: that.$t('lang.gles.common.completedTime'),
      formatter: { type: 'time' },
      minWidth: 160,
      showOverflowTooltip: true
    },
    // 备注
    {
      prop: 'remark',
      isShow: true,
      label: that.$t('lang.gles.orderManage.remark'),
      width: 100,
      showOverflowTooltip: true
    }
  ]
  return searchTableItem
}

// 基础信息
export const getEditBaseFormData = (that, row, options) => [
  // 调整原因
  {
    name: 'type',
    value: row.type || '',
    span: 8,
    component: 'elSelect',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    rules: mapVerify(['required']),
    label: that.$t('lang.gles.orderManage.adjustReason'),
    data: options.adjustReason || []
  },
  // 备注
  {
    name: 'remark',
    value: row.remark || '',
    span: 8,
    component: 'elInput',
    adaptionw: true,
    showWordLimit: false,
    disabled: that.mode === 'detail',
    label: that.$t('lang.gles.orderManage.remark')
  }
]

// 调整对象form
export const getAdjustObjFormData = (that, row, options) => [
  // 工厂编码
  {
    name: 'factoryId',
    span: 8,
    value: row.factoryId || '',
    component: 'elSelect',
    label: that.$t('lang.gles.orderManage.factoryId'),
    filterable: true,
    data: options.factoryList || [],
    mchange: that.handleChange
  },
  // 仓库编码
  {
    name: 'warehouseId',
    value: row.warehouseId || '',
    span: 8,
    component: 'elSelect',
    label: that.$t('lang.gles.orderManage.warehouseId'),
    filterable: true,
    disabled: !!row.workshopId,
    data: that.warehouseList || [],
    mchange: that.handleChange
  },
  // 车间编码
  {
    name: 'workshopId',
    value: row.workshopId || '',
    span: 8,
    component: 'elSelect',
    label: that.$t('lang.gles.orderManage.workshopId'),
    filterable: true,
    disabled: !!row.warehouseId,
    data: that.workshopList || [],
    mchange: that.handleChange
  },
  // 库区编码
  {
    name: 'warehouseAreaId',
    value: row.warehouseAreaId || '',
    span: 8,
    component: 'elSelect',
    label: that.$t('lang.gles.orderManage.warehouseAreaId'),
    filterable: true,
    data: that.warehouseAreaList || [],
    mchange: that.handleChange
  },
  // 库位编码
  {
    name: 'factoryPositionId',
    value: row.factoryPositionId || '',
    span: 8,
    component: 'RemoteSelect',
    label: that.$t('lang.gles.base.factoryPositionCode'),
    labelText: 'factoryPositionCode',
    showLabel: 'factoryPositionCode',
    filterable: true,
    condition(editForm) {
      return Number(that.type) !== 1
    },
    mchange(val, formItem) {
      that.factoryPositionId = val
      that.queryGoodsPositionOption()
    }
  },
  // 库位编码
  {
    name: 'factoryPositionId',
    value: row.factoryPositionId || '',
    span: 8,
    component: 'RemoteSelect',
    label: that.$t('lang.gles.base.factoryPositionCode'),
    labelText: 'factoryPositionCode',
    showLabel: 'factoryPositionCode',
    filterable: true,
    rules: mapVerify(['required']),
    // data: that.factoryPositionList || [],
    condition(editForm) {
      return Number(that.type) === 1
    }
  },
  // 容器类型（固定货位）
  {
    name: 'containerTypeId',
    value: row.containerTypeId || '',
    span: 8,
    component: 'elSelect',
    label: that.$t('lang.gles.orderManage.containerTypePattern'),
    filterable: true,
    data: options.fixedContainerTypeList || [],
    condition(editForm) {
      return Number(that.type) === 2
    },
    mchange: that.containerTypeChange
  },
  // 容器类型（移动货位）
  {
    name: 'containerTypeId',
    value: row.containerTypeId || '',
    span: 8,
    component: 'elSelect',
    label: that.$t('lang.gles.orderManage.containerTypePattern'),
    filterable: true,
    data: options.movableContainerTypeList || [],
    condition(editForm) {
      return Number(that.type) === 3
    },
    mchange: that.containerTypeChange
  },
  // 容器编码
  {
    name: 'containerArchivesId',
    value: row.containerArchivesId || '',
    span: 8,
    component: 'elSelect',
    label: that.$t('lang.gles.orderManage.containerId'),
    filterable: true,
    data: that.containerArchivesList || [],
    condition(editForm) {
      return Number(that.type) !== 1
    },
    mchange(val, formItem, cb) {
      that.containerArchivesId = val
      cb({
        goodsPositionId: ''
      })
      that.queryGoodsPositionOption()
      // that.getBaseData('getGoodsPositionListByType', 'goodsPositionList', 'goodsPositionCode', params)
    }
  },
  // 货位编码
  {
    name: 'goodsPositionId',
    value: row.goodsPositionId || '',
    span: 8,
    component: 'elSelect',
    label: that.$t('lang.gles.orderManage.goodsPositionId'),
    filterable: true,
    rules: mapVerify(['required']),
    data: that.stockGoodsPositionList || [],
    condition(editForm) {
      return Number(that.type) !== 1
    }
  }
]

// 调整明细form
export const getAdjustDetailFormData = (that, row, options) => {
  return [
    // 容器编码
    {
      name: 'containerArchivesId',
      value: row.containerArchivesId || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.containerId'),
      disabled: true,
      data: that.containerArchivesList || [],
      condition(editForm) {
        return Number(that.type) !== 1
      }
    },
    // 货位编码
    {
      name: 'goodsPositionId',
      value: row.goodsPositionId || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.goodsPositionId'),
      disabled: true,
      data: that.stockGoodsPositionList || [],
      condition(editForm) {
        return Number(that.type) !== 1
      }
    },
    // 货位状态
    {
      name: 'goodsPositionStatus',
      value: getValue(row.goodsPositionStatus ?? '0'),
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.workflow.propName.goodsPositionStatus'),
      disabled: true,
      data: options.stockStatusList || [],
      condition(editForm) {
        return Number(that.type) !== 1
      }
    },
    // 库位编码
    {
      name: 'factoryPositionId',
      value: row.factoryPositionId || '',
      span: 10,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.factoryPositionId'),
      disabled: true,
      data: that.factoryPositionList || [],
      condition(editForm) {
        return Number(that.type) === 1
      }
    },
    // 库位状态
    {
      name: 'factoryPositionStatus',
      value: getValue(row.factoryPositionStatus ?? '0'),
      span: 10,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.factoryPositionStatus'),
      disabled: true,
      data: options.stockStatusList || [],
      condition(editForm) {
        return Number(that.type) === 1
      }
    }
  ]
}
// 调整明细新增form
export const getAdjustDetailAddFormData = (that, row, options) => {
  const formData = [
    // 移动容器类型
    {
      name: 'movableContainerTypeId',
      value: row.movableContainerTypeId || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.common.moveContainerType'),
      rules: mapVerify(['required']),
      data: options.movableContainerTypeList || [],
      mchange: that.dialogContainerTypeChange,
      show: Number(that.type) === 1
    },
    // 移动容器类型
    {
      name: 'movableContainerTypeId',
      value: row.movableContainerTypeId || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.common.moveContainerType'),
      data: that.newMoveContainerTypeList || [],
      mchange: that.dialogContainerTypeChange,
      show: Number(that.type) === 2
    },
    // 移动容器编码
    {
      name: 'movableContainerId',
      value: row.movableContainerId || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.movableContainerId'),
      rules: mapVerify(['required']),
      data: that.moveContainerArchivesList || [],
      mchange: that.handleChange,
      show: Number(that.type) === 1
    },
    // 移动容器编码
    {
      name: 'movableContainerId',
      value: row.movableContainerId || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.movableContainerId'),
      data: that.moveContainerArchivesList || [],
      mchange: that.handleChange,
      show: Number(that.type) === 2
    },
    // 移动容器货位编码
    {
      name: 'movableContainerGoodsPositionId',
      value: row.movableContainerGoodsPositionId || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.movableContainerGoodsPositionId'),
      rules: mapVerify(['required']),
      data: that.moverGoodsPositionList || [],
      // mchange: that.validateChange,
      show: Number(that.type) !== 3 && row?.movableContainerId && (row?.turnoverContainerId || row?.materialId)
    },
    // 移动容器货位编码
    {
      name: 'movableContainerGoodsPositionId',
      value: row.movableContainerGoodsPositionId || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.movableContainerGoodsPositionId'),
      data: that.moverGoodsPositionList || [],
      show: Number(that.type) !== 3 && (!row?.movableContainerId || !(row?.turnoverContainerId || row?.materialId))
    },
    // 周转容器类型
    {
      name: 'turnoverContainerTypeId',
      value: row.turnoverContainerTypeId || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.turnoverContainerType'),
      data: options.turnoverContainerTypeList || [],
      mchange: that.dialogContainerTypeChange,
      show: true
    },
    // 周转容器编码
    {
      name: 'turnoverContainerId',
      value: row.turnoverContainerId || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      data: that.turnoverContainerArchivesList || [],
      mchange: that.handleChange,
      show: true
    },
    // 物料编码
    {
      name: 'materialId',
      value: row.materialId || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.materialId'),
      data: options.materialCodeList || [],
      mchange: that.handleChange,
      show: true
    },
    // 物料名称
    {
      name: 'materialName',
      value: row.materialName || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.materialName'),
      data: options.materialNameList || [],
      disabled: true,
      show: true
    },
    // 物料调整数量
    {
      name: 'materialAdjustNum',
      value: row.materialAdjustNum || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.orderManage.materialAdjustNum'),
      rules: mapVerify(['required', 'positiveIntegerLen5']),
      show: row.materialId
      // mchange: that.validateChange
    },
    {
      name: 'materialAdjustNum',
      value: row.materialAdjustNum || '',
      span: 8,
      component: 'elInput',
      rules: mapVerify(['positiveIntegerLen5']),
      label: that.$t('lang.gles.orderManage.materialAdjustNum'),
      show: !row.materialId
    },
    // 物料调整计量数量
    {
      name: 'materialMeasuringAdjustNum',
      value: row.materialMeasuringAdjustNum || '',
      span: 8,
      component: 'elInput',
      rules: mapVerify(['positiveIntegerLen5']),
      label: that.$t('lang.gles.orderManage.materialMeasuringAdjustNum'),
      show: true
    },
    // 等级状态
    {
      name: 'materialGradeStatus',
      value: row.materialGradeStatus || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.levelStatus'),
      data: options.gradeStatusList || [],
      rules: mapVerify(['required']),
      // mchange(val, formItem, cb) {
      //   cb({
      //     materialGradeStatus: val
      //   })
      // },
      show: row?.materialId
    },
    // 等级状态
    {
      name: 'materialGradeStatus',
      value: row.materialGradeStatus || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.levelStatus'),
      data: options.gradeStatusList || [],
      show: !row?.materialId
    }
  ]
  return formData.filter((item) => {
    return item.show
  })
  // return formData
}

// 调整明细编辑form
export const getAdjustDetailEditFormData = (that, row, options, editRow) => {
  console.log(editRow.turnoverContainerId)
  let formData = []
  // 库位编辑
  if (editRow.adjustTarget === 1) {
    formData = [
      // 移动容器类型
      {
        name: 'movableContainerTypeId',
        value: row.movableContainerTypeId || '',
        span: 8,
        component: 'elSelect',
        label: that.$t('lang.gles.common.moveContainerType'),
        disabled: true,
        data: options.movableContainerTypeList || []
      },
      // 移动容器编码
      {
        name: 'movableContainerId',
        value: row.movableContainerId || '',
        span: 8,
        component: 'elSelect',
        label: that.$t('lang.gles.orderManage.movableContainerId'),
        disabled: true,
        data: options.containerArchivesAllList || []
      },
      // 移动容器可用数量
      {
        name: 'movableContainerAvailableNum',
        value: getValue(row.movableContainerAvailableNum),
        span: 8,
        component: 'elInput',
        disabled: true,
        label: that.$t('lang.gles.orderManage.movableContainerAvailableNum')
      },
      // 移动容器调整数量
      {
        name: 'movableContainerAdjustNum',
        value: getValue(row.movableContainerAdjustNum),
        span: 8,
        component: 'elInput',
        rules: [
          {
            pattern: /^0$/,
            message: `${that.$t('lang.gles.common.pleaseInput')}0`
          }
        ],
        label: that.$t('lang.gles.orderManage.movableContainerAdjustNum')
      }
    ]
  } else {
    // a 移动容器+周转容器+物料，移动容器+周转容器，移动容器+物料，移动容器
    if (editRow.movableContainerId) {
      formData = [
        // 移动容器类型
        {
          name: 'movableContainerTypeId',
          value: row.movableContainerTypeId || '',
          span: 8,
          component: 'elSelect',
          label: that.$t('lang.gles.common.moveContainerType'),
          disabled: true,
          data: options.movableContainerTypeList || []
        },
        // 移动容器编码
        {
          name: 'movableContainerId',
          value: row.movableContainerId || '',
          span: 8,
          component: 'elSelect',
          label: that.$t('lang.gles.orderManage.movableContainerId'),
          disabled: true,
          data: options.containerArchivesAllList || []
        },
        // 移动容器可用数量
        {
          name: 'movableContainerAvailableNum',
          value: getValue(row.movableContainerAvailableNum),
          span: 8,
          component: 'elInput',
          disabled: true,
          label: that.$t('lang.gles.orderManage.movableContainerAvailableNum')
        },
        // 移动容器调整数量
        {
          name: 'movableContainerAdjustNum',
          value: getValue(row.movableContainerAdjustNum),
          span: 8,
          component: 'elInput',
          rules: [
            {
              pattern: /^0$/,
              message: `${that.$t('lang.gles.common.pleaseInput')}0`
            }
          ],
          label: that.$t('lang.gles.orderManage.movableContainerAdjustNum')
        }
      ]
    } else if (editRow.turnoverContainerId) { // b 周转容器+物料  c 周转容器
      if (editRow.materialId) {
        formData = [
          // 周转容器类型
          {
            name: 'turnoverContainerTypeId',
            value: row.turnoverContainerTypeId || '',
            span: 8,
            component: 'elSelect',
            disabled: true,
            label: that.$t('lang.gles.orderManage.turnoverContainerType'),
            data: options.turnoverContainerTypeList || []
          },
          // 周转容器编码
          {
            name: 'turnoverContainerId',
            value: row.turnoverContainerId || '',
            span: 8,
            component: 'elSelect',
            disabled: true,
            label: that.$t('lang.gles.orderManage.turnoverContainerId'),
            data: options.containerArchivesAllList
          },
          // 周转容器可用数量
          {
            name: 'turnoverContainerAvailableNum',
            value: getValue(row.turnoverContainerAvailableNum),
            span: 8,
            component: 'elInput',
            disabled: true,
            label: that.$t('lang.gles.orderManage.turnoverContainerAvailableNum')
          },
          // 周转容器调整数量
          {
            name: 'turnoverContainerAdjustNum',
            value: getValue(row.turnoverContainerAdjustNum),
            span: 8,
            component: 'elInput',
            rules: [
              {
                pattern: /^0$/,
                message: `${that.$t('lang.gles.common.pleaseInput')}0`
              }
            ],
            label: that.$t('lang.gles.orderManage.turnoverContainerAdjustNum')
          },
          // 物料编码
          {
            name: 'materialId',
            value: row.materialId || '',
            span: 8,
            component: 'elSelect',
            disabled: true,
            label: that.$t('lang.gles.orderManage.materialId'),
            data: options.materialCodeList || []
          },
          // 物料名称
          {
            name: 'materialName',
            value: row.materialName || '',
            span: 8,
            component: 'elSelect',
            disabled: true,
            data: options.materialNameList || [],
            label: that.$t('lang.gles.orderManage.materialName')
          },
          // 物料可用数量
          {
            name: 'materialAvailableNum',
            value: getValue(row.materialAvailableNum),
            span: 8,
            component: 'elInput',
            disabled: true,
            label: that.$t('lang.gles.orderManage.materialAvailableNum')
          },
          // 物料调整数量
          {
            name: 'materialAdjustNum',
            value: getValue(row.materialAdjustNum),
            span: 8,
            component: 'elInput',
            rules: mapVerify(['positiveIntegerLen5']),
            label: that.$t('lang.gles.orderManage.materialAdjustNum')
          },
          // 物料可用计量数量
          {
            name: 'materialMeasuringAvailableNum',
            value: getValue(row.materialMeasuringAvailableNum),
            span: 8,
            component: 'elInput',
            disabled: true,
            label: that.$t('lang.gles.orderManage.materialMeasuringAvailableNum')
          },
          // 物料调整计量数量
          {
            name: 'materialMeasuringAdjustNum',
            value: getValue(row.materialMeasuringAdjustNum),
            span: 8,
            component: 'elInput',
            rules: mapVerify(['positiveIntegerLen5']),
            label: that.$t('lang.gles.orderManage.materialMeasuringAdjustNum')
          },
          // 等级状态
          {
            name: 'materialGradeStatus',
            value: row.materialGradeStatus || '',
            span: 8,
            component: 'elSelect',
            label: that.$t('lang.gles.orderManage.levelStatus'),
            data: options.gradeStatusList || [],
            rules: mapVerify(['required'])
          },
          // 等级状态
          {
            name: 'materialGradeStatus',
            value: row.materialGradeStatus || '',
            span: 8,
            component: 'elSelect',
            label: that.$t('lang.gles.orderManage.levelStatus'),
            data: options.gradeStatusList || [],
            condition(editForm) {
              return !row?.materialId
            }
          }
        ]
      } else {
        formData = [
          // 周转容器类型
          {
            name: 'turnoverContainerTypeId',
            value: row.turnoverContainerTypeId || '',
            span: 8,
            component: 'elSelect',
            disabled: true,
            label: that.$t('lang.gles.orderManage.turnoverContainerType'),
            data: options.turnoverContainerTypeList || []
          },
          // 周转容器编码
          {
            name: 'turnoverContainerId',
            value: row.turnoverContainerId || '',
            span: 8,
            component: 'elSelect',
            disabled: true,
            label: that.$t('lang.gles.orderManage.turnoverContainerId'),
            data: options.containerArchivesAllList
          },
          // 周转容器可用数量
          {
            name: 'turnoverContainerAvailableNum',
            value: getValue(row.turnoverContainerAvailableNum),
            span: 8,
            component: 'elInput',
            disabled: true,
            label: that.$t('lang.gles.orderManage.turnoverContainerAvailableNum')
          },
          // 周转容器调整数量
          {
            name: 'turnoverContainerAdjustNum',
            value: getValue(row.turnoverContainerAdjustNum),
            span: 8,
            component: 'elInput',
            rules: [
              {
                pattern: /^0$/,
                message: `${that.$t('lang.gles.common.pleaseInput')}0`
              }
            ],
            label: that.$t('lang.gles.orderManage.turnoverContainerAdjustNum')
          },
          // 物料编码
          {
            name: 'materialId',
            value: row.materialId || '',
            span: 8,
            component: 'elSelect',
            label: that.$t('lang.gles.orderManage.materialId'),
            data: options.materialCodeList || [],
            mchange: that.handleChange
          },
          // 物料名称
          {
            name: 'materialName',
            value: row.materialName || '',
            span: 8,
            component: 'elSelect',
            data: options.materialNameList || [],
            disabled: true,
            label: that.$t('lang.gles.orderManage.materialName')
          },
          // 物料可用数量
          {
            name: 'materialAvailableNum',
            value: getValue(row.materialAvailableNum),
            span: 8,
            component: 'elInput',
            disabled: true,
            label: that.$t('lang.gles.orderManage.materialAvailableNum')
          },
          // 物料调整数量
          {
            name: 'materialAdjustNum',
            value: getValue(row.materialAdjustNum),
            span: 8,
            component: 'elInput',
            rules: mapVerify(['positiveIntegerLen5']),
            label: that.$t('lang.gles.orderManage.materialAdjustNum')
          },
          // 物料可用计量数量
          {
            name: 'materialMeasuringAvailableNum',
            value: getValue(row.materialMeasuringAvailableNum),
            span: 8,
            component: 'elInput',
            disabled: true,
            label: that.$t('lang.gles.orderManage.materialMeasuringAvailableNum')
          },
          // 物料调整计量数量
          {
            name: 'materialMeasuringAdjustNum',
            value: getValue(row.materialMeasuringAdjustNum),
            span: 8,
            component: 'elInput',
            rules: mapVerify(['positiveIntegerLen5']),
            label: that.$t('lang.gles.orderManage.materialMeasuringAdjustNum')
          },
          // 等级状态
          {
            name: 'materialGradeStatus',
            value: row.materialGradeStatus || '',
            span: 8,
            component: 'elSelect',
            label: that.$t('lang.gles.orderManage.levelStatus'),
            data: options.gradeStatusList || [],
            rules: mapVerify(['required']),
            condition(editForm) {
              return row?.materialId
            }
          },
          // 等级状态
          {
            name: 'materialGradeStatus',
            value: row.materialGradeStatus || '',
            span: 8,
            component: 'elSelect',
            label: that.$t('lang.gles.orderManage.levelStatus'),
            data: options.gradeStatusList || [],
            condition(editForm) {
              return !row?.materialId
            }
          }
        ]
      }
    } else { // d 物料
      formData = [
        // 物料编码
        {
          name: 'materialId',
          value: row.materialId || '',
          span: 8,
          component: 'elSelect',
          label: that.$t('lang.gles.orderManage.materialId'),
          data: options.materialCodeList || [],
          disabled: true
        },
        // 物料名称
        {
          name: 'materialName',
          value: row.materialName || '',
          span: 8,
          component: 'elSelect',
          disabled: true,
          data: options.materialNameList || [],
          label: that.$t('lang.gles.orderManage.materialName')
        },
        // 物料可用数量
        {
          name: 'materialAvailableNum',
          value: getValue(row.materialAvailableNum),
          span: 8,
          component: 'elInput',
          disabled: true,
          label: that.$t('lang.gles.orderManage.materialAvailableNum')
        },
        // 物料调整数量
        {
          name: 'materialAdjustNum',
          value: getValue(row.materialAdjustNum),
          span: 8,
          component: 'elInput',
          rules: mapVerify(['positiveIntegerLen5']),
          label: that.$t('lang.gles.orderManage.materialAdjustNum')
        },
        // 物料可用计量数量
        {
          name: 'materialMeasuringAvailableNum',
          value: getValue(row.materialMeasuringAvailableNum),
          span: 8,
          component: 'elInput',
          disabled: true,
          label: that.$t('lang.gles.orderManage.materialMeasuringAvailableNum')
        },
        // 物料调整计量数量
        {
          name: 'materialMeasuringAdjustNum',
          value: getValue(row.materialMeasuringAdjustNum),
          span: 8,
          component: 'elInput',
          rules: mapVerify(['positiveIntegerLen5']),
          label: that.$t('lang.gles.orderManage.materialMeasuringAdjustNum')
        },
        // 等级状态
        {
          name: 'materialGradeStatus',
          value: row.materialGradeStatus || '',
          span: 8,
          component: 'elSelect',
          label: that.$t('lang.gles.orderManage.levelStatus'),
          data: options.gradeStatusList || [],
          rules: mapVerify(['required'])
          // condition(editForm) {
          //   return row?.materialId
          // }
        }
      ]
    }
  }
  return formData
}

// 调整明细新增批属性form
export const getPropertyFormData = (that, row, options) => {
  const formData = []
  // console.log(row)
  that.batchPropertyDetail.forEach(item => {
    if (item.enableFlag === 1) {
      if (item.propertyLabelPattern === 1) {
        formData.push({
          name: item.propertyLabelCode,
          value: row[item.propertyLabelCode] || '',
          span: 8,
          component: 'elDatePicker',
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          label: that.$t(item.propertyLabelDesc),
          rules: item.requiredFlag === 1 ? mapVerify(['required']) : [],
          mchange: that.propertyChange
        })
      } else if (item.propertyLabelPattern === 2) {
        formData.push({
          name: item.propertyLabelCode,
          value: row[item.propertyLabelCode] || '',
          span: 8,
          component: 'elDatePicker',
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          label: that.$t(item.propertyLabelDesc),
          mchange: that.propertyChange
        })
      } else {
        formData.push({
          name: item.propertyLabelCode,
          value: row[item.propertyLabelCode] || '',
          span: 8,
          component: 'elInput',
          label: that.$t(item.propertyLabelDesc),
          mchange: that.propertyChange
        })
      }
    }
  })

  return formData
}

// 调整明细查看批属性form
export const getPropertyDetailFormData = (that, row, options) => {
  const formData = []
  console.log(row)
  that.batchPropertyDetail.forEach(item => {
    if (item.enableFlag === 1) {
      if (item.propertyLabelPattern === 1) {
        formData.push({
          name: item.propertyLabelCode,
          value: row[item.propertyLabelCode] || '',
          span: 12,
          component: 'elDatePicker',
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          disabled: true,
          label: that.$t(item.propertyLabelDesc)
        })
      } else if (item.propertyLabelPattern === 2) {
        formData.push({
          name: item.propertyLabelCode,
          value: row[item.propertyLabelCode] || '',
          span: 12,
          component: 'elDatePicker',
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          disabled: true,
          label: that.$t(item.propertyLabelDesc)
        })
      } else {
        formData.push({
          name: item.propertyLabelCode,
          value: row[item.propertyLabelCode] || '',
          span: 12,
          component: 'elInput',
          disabled: true,
          label: that.$t(item.propertyLabelDesc)
        })
      }
    }
  })

  return formData
}

// 调整明细查看批属性table
export const getPropertyDetailTable = (that, row, options) => {
  const formData = []
  console.log(row)
  that.batchPropertyDetail.forEach(item => {
    if (item.enableFlag === 1) {
      formData.push({
        prop: item.propertyLabelCode,
        isShow: true,
        label: that.$t(item.propertyLabelDesc),
        width: 100
      })
    }
  })

  return formData
}

// 库存调整明细
export const getDetailTableItems = (that, options) => {
  const searchTableItem = [
    // 调整对象
    {
      prop: 'adjustTarget',
      isShow: true,
      label: that.$t('lang.gles.orderManage.adjustTarget'),
      width: 100,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.adjustTargetList, row[column])
      }
    },
    // 库位编码
    {
      prop: 'factoryPositionId',
      isShow: true,
      minWidth: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.factoryPositionId'),
      formatter(row, column) {
        return getFormatter(that.newFactoryPositionList, row[column])
      }
    },
    // 固定容器编码
    {
      prop: 'fixedContainerId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.fixedContainerId'),
      width: 120,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(that.newContainerArchivesAllList, row[column])
      }
    },
    // 固定货位编码
    {
      prop: 'fixedGoodsPositionId',
      isShow: true,
      minWidth: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.fixedGoodsPositionId'),
      formatter(row, column) {
        return getFormatter(that.newGoodsPositionList, row[column])
      }
    },
    // 移动容器类型
    {
      prop: 'movableContainerTypeId',
      isShow: true,
      minWidth: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.common.moveContainerType'),
      formatter(row, column) {
        return getFormatter(that.newMovableContainerTypeList, row[column])
      }
    },
    // 移动容器编码
    {
      prop: 'movableContainerId',
      isShow: true,
      minWidth: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.movableContainerId'),
      formatter(row, column) {
        return getFormatter(that.newContainerArchivesAllList, row[column])
      }
    },
    // 移动容器可用数量
    {
      prop: 'movableContainerAvailableNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.movableContainerAvailableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 移动容器调整数量
    {
      prop: 'movableContainerAdjustNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.movableContainerAdjustNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 移动容器货位编码
    {
      prop: 'movableContainerGoodsPositionId',
      isShow: true,
      minWidth: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.movableContainerGoodsPositionId'),
      formatter(row, column) {
        return getFormatter(that.newGoodsPositionList, row[column])
      }
    },
    // 周转容器类型
    {
      prop: 'turnoverContainerTypeId',
      isShow: true,
      minWidth: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerType'),
      formatter(row, column) {
        return getFormatter(that.newTurnoverContainerTypeList, row[column])
      }
    },
    // 周转容器编码
    {
      prop: 'turnoverContainerId',
      isShow: true,
      minWidth: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      formatter(row, column) {
        return getFormatter(that.newContainerArchivesAllList, row[column])
      }
    },
    // 周转容器可用数量
    {
      prop: 'turnoverContainerAvailableNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerAvailableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 周转容器调整数量
    {
      prop: 'turnoverContainerAdjustNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerAdjustNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 物料编码
    {
      prop: 'materialId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialId'),
      width: 100,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(that.newMaterialCodeList, row[column])
      }
    },
    // 物料名称
    {
      prop: 'materialName',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialName'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(that.newMaterialNameList, row[column])
      }
    },
    // 物料可用数量
    {
      prop: 'materialAvailableNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialAvailableNum')
    },
    // 物料调整数量
    {
      prop: 'materialAdjustNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialAdjustNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 基本单位
    {
      prop: 'materialBasicUnit',
      isShow: true,
      label: that.$t('lang.gles.common.basicUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 物料可用计量数量
    {
      prop: 'materialMeasuringAvailableNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialMeasuringAvailableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 物料调整计量数量
    {
      prop: 'materialMeasuringAdjustNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialMeasuringAdjustNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 物料计量单位
    {
      prop: 'materialMeasuringUnit',
      isShow: true,
      label: that.$t('lang.gles.common.measureUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 等级状态
    {
      prop: 'materialGradeStatus',
      isShow: true,
      label: that.$t('lang.gles.orderManage.levelStatus'),
      formatter(row, column) {
        return getFormatter(options.gradeStatusList, row[column])
      }
    },
    // 批属性
    {
      prop: 'assignInGoodsPositionId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.batchProperties'),
      slotName: 'clickSlot'
    }
  ]
  return searchTableItem
}

// 新增明细table
export const getAddDetailTableItems = (that, options) => {
  const searchTableItem = [
    // 移动容器类型
    {
      prop: 'movableContainerTypeId',
      isShow: false,
      label: that.$t('lang.gles.common.moveContainerType'),
      formatter(row, column) {
        return getFormatter(that.newMovableContainerTypeList, row[column])
      }
    },
    // 移动容器编码
    {
      prop: 'movableContainerId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.movableContainerId'),
      formatter(row, column) {
        return getFormatter(that.newContainerArchivesAllList, row[column])
      }
    },
    // 移动容器可用数量
    {
      prop: 'movableContainerAvailableNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.movableContainerAvailableNum')
    },
    // 移动容器调整数量
    {
      prop: 'movableContainerAdjustNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.movableContainerAdjustNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 移动容器货位编码
    {
      prop: 'movableContainerGoodsPositionId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.movableContainerGoodsPositionId'),
      formatter(row, column) {
        return getFormatter(that.newGoodsPositionList, row[column])
      }
    },
    // 周转容器类型
    {
      prop: 'turnoverContainerTypeId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerType'),
      formatter(row, column) {
        return getFormatter(that.newTurnoverContainerTypeList, row[column])
      }
    },
    // 周转容器编码
    {
      prop: 'turnoverContainerId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      formatter(row, column) {
        return getFormatter(that.newContainerArchivesAllList, row[column])
      }
    },
    // 周转容器可用数量
    {
      prop: 'turnoverContainerAvailableNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerAvailableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 周转容器调整数量
    {
      prop: 'turnoverContainerAdjustNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerAdjustNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 物料编码
    {
      prop: 'materialId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialId'),
      width: 100,
      formatter(row, column) {
        return getFormatter(that.newMaterialCodeList, row[column])
      }
    },
    // 物料名称
    {
      prop: 'materialName',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialName'),
      formatter(row, column) {
        return getFormatter(that.newMaterialNameList, row[column])
      }
    },
    // 物料可用数量
    {
      prop: 'materialAvailableNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialAvailableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 物料调整数量
    {
      prop: 'materialAdjustNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialAdjustNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 基本单位
    {
      prop: 'materialBasicUnit',
      isShow: true,
      label: that.$t('lang.gles.common.basicUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 物料可用计量数量
    {
      prop: 'materialMeasuringAvailableNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialMeasuringAvailableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 物料调整计量数量
    {
      prop: 'materialMeasuringAdjustNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialMeasuringAdjustNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 物料计量单位
    {
      prop: 'materialMeasuringUnit',
      isShow: true,
      label: that.$t('lang.gles.common.measureUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 等级状态
    {
      prop: 'materialGradeStatus',
      isShow: true,
      label: that.$t('lang.gles.orderManage.levelStatus'),
      formatter(row, column) {
        return getFormatter(options.gradeStatusList, row[column])
      }
    },
    // 批属性
    {
      prop: 'workshopCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.batchProperties'),
      slotName: 'clickSlot'
    }
  ]
  return searchTableItem
}
