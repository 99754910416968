<template>
  <!-- 主表弹框 -->
  <m-dialog :visible="visible" width="60%" :is-need-footer="false" @closed="closed">
    <!-- 标题 -->
    <template #title>
      <span class="f16">
        {{ title }}
      </span>
    </template>
    <!-- 编辑 -->
    <div>
      <!-- 批属性 -->
      <m-form
        ref="propertyForm"
        label-position="right"
        :extend-config="extendFormConfig"
        :label-width="100"
        :form-data="propertyFormData"
      />
      <!-- <m-table
        border
        :table-item="tableItem"
        :table-data="batchPropertyDetail"
        :page-data="pageData"
      /> -->
    </div>
  </m-dialog>
</template>
<script>
import { getPropertyDetailFormData, getPropertyDetailTable } from '../data'

export default {
  props: {
    visible: Boolean,
    mode: {
      type: String,
      default: 'add'
    },
    initRow: {
      type: Object,
      default: () => ({})
    },
    list: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      pageData: null,
      title: this.$t('lang.gles.material.batchProperty'),
      extendFormConfig: {
        isNeedBtn: false
      },
      formModel: {},
      batchPropertyForm: {},
      turnoverContainerArchivesList: [],
      moveContainerArchivesList: [],
      // 批属性列表
      batchPropertyDetail: []
    }
  },
  computed: {
    propertyFormData() {
      const options = this.options
      return getPropertyDetailFormData(this, { ...this.batchPropertyForm }, options)
    },
    tableItem() {
      return getPropertyDetailTable(this)
    }
  },
  created() {
    this.batchPropertyForm = this.initRow
    this.batchPropertyDetail = this.list
  },
  methods: {
    // 关闭
    closed() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style lang="scss">
.ui-containerBox .el-input--prefix .el-input__inner {
  padding-left: 15px;
}
</style>
